<template>
  <div v-if="ready" class="min-h-screen min-w-screen bg-primary flex justify-center flex-col">
    <div class="m-auto md:max-w-[calc(100vw-100px)] p-4">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">

  const router = useRouter()
  const auth = useAuthStore() 
  
  const ready = ref<boolean>(false)
  
  watch(auth, (current, old) => {
    if(current.signedIn)
      router.replace('/')
  })
  
  onMounted(() => {
    if(auth.signedIn)
      router.replace('/')
    else 
      ready.value = true
  })
</script>

